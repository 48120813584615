/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.FileSystem',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      const DirectoryEntry = require('./DirectoryEntry');

      /**
       * An interface representing a file system
       *
       * @constructor
       * {DOMString} name the unique name of the file system (readonly)
       * {DirectoryEntry} root directory of the file system (readonly)
       */
      const FileSystem = function (name, root) {
        this.name = name;
        if (root) {
          this.root = new DirectoryEntry(
            root.name,
            root.fullPath,
            this,
            root.nativeURL
          );
        } else {
          this.root = new DirectoryEntry(this.name, '/', this);
        }
      };

      FileSystem.prototype.__format__ = function (fullPath, nativeUrl) {
        return fullPath;
      };

      FileSystem.prototype.toJSON = function () {
        return '<FileSystem: ' + this.name + '>';
      };

      // Use instead of encodeURI() when encoding just the path part of a URI rather than an entire URI.
      FileSystem.encodeURIPath = function (path) {
        // Because # is a valid filename character, it must be encoded to prevent part of the
        // path from being parsed as a URI fragment.
        return encodeURI(path).replace(/#/g, '%23');
      };

      module.exports = FileSystem;
    }
  );
};
