import { useInitialize } from '../../generic/use';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Coinscrap } from './others/Coinscrap';
import { FacebookPixel } from './others/FacebookPixel';
import { GoogleAnalytics } from './others/GoogleAnalytics';
import { GoogleTagManager } from './others/GoogleTagManager';
import { Hotjar } from './others/Hotjar';
import { Teallium } from './others/Teallium';
import { TRANSPORTS } from './others/transportDefinitions';
import { WalmericDelio } from './others/WalmericDelio';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      bindedContexts: { user, getInstanceApi, loadSessionUser },
      componentProps,
    }) => {
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];

      // console.log(`## 🥸 LOGIC USER`, user);

      useInitialize(() => {
        for (let i = 0; i < transports.length; i++) {
          const transport = transports[i];
          // console.log('## ➕ INITING ' + transport.engine + ' Wrapper');
          switch (transport.engine) {
            case TRANSPORTS.COINSCRAP:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                Coinscrap.initialize(getInstanceApi, user, loadSessionUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.FACEBOOK_PIXEL:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                FacebookPixel.initialize(
                  getInstanceApi,
                  user,
                  loadSessionUser,
                  {
                    ...transport.initProps,
                    // debugEnabled
                  }
                );
              }
              break;
            case TRANSPORTS.GOOGLE_ANALYTICS:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                GoogleAnalytics.initialize(
                  getInstanceApi,
                  user,
                  loadSessionUser,
                  {
                    ...transport.initProps,
                    // debugEnabled
                  }
                );
              }
              break;
            case TRANSPORTS.GOOGLE_TAG_MANAGER:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                GoogleTagManager.initialize(
                  getInstanceApi,
                  user,
                  loadSessionUser,
                  {
                    ...transport.initProps,
                    // debugEnabled
                  }
                );
              }
              break;
            case TRANSPORTS.HOTJAR:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                Hotjar.initialize(getInstanceApi, user, loadSessionUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.TEALLIUM:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                Teallium.initialize(getInstanceApi, user, loadSessionUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.WALMERIC_DELIO:
              if (
                i === 0 ||
                user?.metadata?.analytics?.[transports[i - 1]?.engine] ||
                !user
              ) {
                WalmericDelio.initialize(
                  getInstanceApi,
                  user,
                  loadSessionUser,
                  {
                    ...transport.initProps,
                    // debugEnabled, useMainScript
                  }
                );
              }
              break;
            default:
              throw new Error('Analytics transport not supported');
          }
        }
      }, true);
    }
  );
