/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.ProgressEvent',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      // If ProgressEvent exists in global context, use it already, otherwise use our own polyfill
      // Feature test: See if we can instantiate a native ProgressEvent;
      // if so, use that approach,
      // otherwise fill-in with our own implementation.
      //
      // NOTE: right now we always fill in with our own. Down the road would be nice if we can use whatever is native in the webview.
      const ProgressEvent = (function () {
        /*
        var createEvent = function(data) {
            var event = document.createEvent('Events');
            event.initEvent('ProgressEvent', false, false);
            if (data) {
                for (var i in data) {
                    if (data.hasOwnProperty(i)) {
                        event[i] = data[i];
                    }
                }
                if (data.target) {
                    // TODO: cannot call <some_custom_object>.dispatchEvent
                    // need to first figure out how to implement EventTarget
                }
            }
            return event;
        };
        try {
            var ev = createEvent({type:"abort",target:document});
            return function ProgressEvent(type, data) {
                data.type = type;
                return createEvent(data);
            };
        } catch(e){
        */
        return function ProgressEvent(type, dict) {
          this.type = type;
          this.bubbles = false;
          this.cancelBubble = false;
          this.cancelable = false;
          this.lengthComputable = false;
          this.loaded = dict && dict.loaded ? dict.loaded : 0;
          this.total = dict && dict.total ? dict.total : 0;
          this.target = dict && dict.target ? dict.target : null;
        };
        // }
      })();

      module.exports = ProgressEvent;
    }
  );
};
