/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.FileUploadOptions',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      /**
       * Options to customize the HTTP request used to upload files.
       * @constructor
       * @param fileKey {String}   Name of file request parameter.
       * @param fileName {String}  Filename to be used by the server. Defaults to image.jpg.
       * @param mimeType {String}  Mimetype of the uploaded file. Defaults to image/jpeg.
       * @param params {Object}    Object with key: value params to send to the server.
       * @param headers {Object}   Keys are header names, values are header values. Multiple
       *                           headers of the same name are not supported.
       */
      const FileUploadOptions = function (
        fileKey,
        fileName,
        mimeType,
        params,
        headers,
        httpMethod
      ) {
        this.fileKey = fileKey || null;
        this.fileName = fileName || null;
        this.mimeType = mimeType || null;
        this.params = params || null;
        this.headers = headers || null;
        this.httpMethod = httpMethod || null;
      };

      module.exports = FileUploadOptions;
    }
  );
};
