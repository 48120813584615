/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.FileError',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      /**
       * FileError
       */
      function FileError(error) {
        this.code = error || null;
      }

      // File error codes
      // Found in DOMException
      FileError.NOT_FOUND_ERR = 1;
      FileError.SECURITY_ERR = 2;
      FileError.ABORT_ERR = 3;

      // Added by File API specification
      FileError.NOT_READABLE_ERR = 4;
      FileError.ENCODING_ERR = 5;
      FileError.NO_MODIFICATION_ALLOWED_ERR = 6;
      FileError.INVALID_STATE_ERR = 7;
      FileError.SYNTAX_ERR = 8;
      FileError.INVALID_MODIFICATION_ERR = 9;
      FileError.QUOTA_EXCEEDED_ERR = 10;
      FileError.TYPE_MISMATCH_ERR = 11;
      FileError.PATH_EXISTS_ERR = 12;

      module.exports = FileError;
    }
  );
};
