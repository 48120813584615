/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.requestFileSystem',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      (function () {
        // For browser platform: not all browsers use this file.
        function checkBrowser() {
          if (cordova.platformId === 'browser' && require('./isChrome')()) {
            module.exports =
              window.requestFileSystem || window.webkitRequestFileSystem;
            return true;
          }
          return false;
        }
        if (checkBrowser()) {
          return;
        }

        const argscheck = require('cordova/argscheck');
        const FileError = require('./FileError');
        const FileSystem = require('./FileSystem');
        const exec = require('cordova/exec');
        const fileSystems = require('./fileSystems');

        /**
         * Request a file system in which to store application data.
         * @param type  local file system type
         * @param size  indicates how much storage space, in bytes, the application expects to need
         * @param successCallback  invoked with a FileSystem object
         * @param errorCallback  invoked if error occurs retrieving file system
         */
        const requestFileSystem = function (
          type,
          size,
          successCallback,
          errorCallback
        ) {
          argscheck.checkArgs('nnFF', 'requestFileSystem', arguments);
          const fail = function (code) {
            if (errorCallback) {
              errorCallback(new FileError(code));
            }
          };

          if (type < 0) {
            fail(FileError.SYNTAX_ERR);
          } else {
            // if successful, return a FileSystem object
            const success = function (file_system) {
              if (file_system) {
                if (successCallback) {
                  fileSystems.getFs(file_system.name, function (fs) {
                    // This should happen only on platforms that haven't implemented requestAllFileSystems (windows)
                    if (!fs) {
                      fs = new FileSystem(file_system.name, file_system.root);
                    }
                    successCallback(fs);
                  });
                }
              } else {
                // no FileSystem object returned
                fail(FileError.NOT_FOUND_ERR);
              }
            };
            exec(success, fail, 'File', 'requestFileSystem', [type, size]);
          }
        };

        module.exports = requestFileSystem;
      })();
    }
  );
};
