import { Logger, UserInput } from '../../generic/utils';
import { useEffect } from 'react';
import qs from 'qs';
import queryString from 'query-string';
import {
  RouteNavigation,
  NavigationEventGenerationProps,
  QueryParams,
  NavigateToProps,
  RouteNavigationComplex,
} from './interfaces';
import { wrapperIdentifier } from './common';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export let params = {};

export let lastRouteChange = 'init';

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'pushRoute',
    ({
      componentProps: { history },
      stateHandler: { state, dispatch },
    }) => routeReceived => {
      lastRouteChange = 'push';
      const route =
        routeReceived ||
        (state?.routes
          ? state.routes[state.routes.length - 1]
          : history.location.pathname + history.location.search);
      console.log(wrapperIdentifier + ': Pushing route - ' + route, history);
      routeReceived &&
        dispatch({ type: DISPATCH_TYPES.PUSH_ROUTE, payload: route });
      history.push(route);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'replaceRoute',
    ({
      componentProps: { history },
      stateHandler: { dispatch },
    }) => routeReceived => {
      lastRouteChange = 'replace';
      const route =
        routeReceived || history.location.pathname + history.location.search;
      console.log(wrapperIdentifier + ': Replacing route - ' + route);
      routeReceived &&
        dispatch({ type: DISPATCH_TYPES.REPLACE_ROUTE, payload: route });
      history.replace(route);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('goBack', ({ componentProps: { history } }) => () => {
    console.log(wrapperIdentifier + ': Back');
    //dispatch("goBack"); // -> dispach triggered on window.popstate listener
    history.goBack();
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'triggerNav',
    ({ componentProps, coreDynamicFunctionGetter }) => (
      key,
      eventUserProps
    ) => {
      let navigateTo = coreDynamicFunctionGetter('navigateTo');
      const { navigationEvents, history } = componentProps;
      const propsHandle = {
        ...eventUserProps,
      };
      console.log('ROUTE PROPS EVENT', propsHandle);

      if (!navigationEvents || !navigationEvents[key]) {
        console.log('NO EVENT');
        return;
      }
      console.log('EVENT', navigationEvents[key]);

      let routeToGo: RouteNavigation | undefined = undefined;

      let eventProps: NavigationEventGenerationProps = {
        pathname: history.location.pathname,
        ...qs.parse(history.location.search, {
          ignoreQueryPrefix: true,
        }),
        ...params,
        ...propsHandle,
      };
      console.log('PROPS EVENT', eventProps);
      // Query params generation
      let queryParamsFunctionOrObject =
        (navigationEvents[key] && navigationEvents[key].queryParams) || {};
      let queryParams: QueryParams = {};
      if (typeof queryParamsFunctionOrObject === 'function') {
        queryParams = queryParamsFunctionOrObject(eventProps);
      } else {
        queryParams = queryParamsFunctionOrObject;
      }

      console.log('PARAMS EVENT', queryParams);
      // Has redirect?
      let handleRedirection =
        navigationEvents[key] && navigationEvents[key].handleRedirection;
      let redirect = false;
      if (typeof handleRedirection === 'function') {
        redirect = handleRedirection(eventProps);
      } else if (typeof handleRedirection === 'boolean') {
        redirect = handleRedirection || redirect;
      }

      console.log('REDIRECT EVENT', redirect);

      // Has clear?
      let clearHandler =
        navigationEvents[key] && navigationEvents[key].clearItems;
      let clear: string[] | undefined = undefined;
      if (typeof clearHandler === 'function') {
        clear = clearHandler(eventProps);
      } /*if (typeof clearHandler === 'boolean') */ else {
        clear = clearHandler || undefined;
      }

      // Has resetHistory?
      let resetHistoryHandler =
        navigationEvents[key] && navigationEvents[key].resetHistory;
      let resetHistory = false;
      if (typeof resetHistoryHandler === 'function') {
        resetHistory = resetHistoryHandler(eventProps);
      } else if (typeof resetHistoryHandler === 'boolean') {
        resetHistory = resetHistoryHandler || false;
      }

      let navigationMode: 'replace' | 'push' = 'push';
      // Route generation
      let route = navigationEvents[key] && navigationEvents[key].route;
      if (typeof route === 'function') {
        const auxRoute = route(eventProps);
        if (typeof auxRoute === 'string') {
          routeToGo = {
            name: auxRoute,
            params: {
              ...eventProps,
            },
          };
        } else {
          routeToGo = auxRoute;
        }
      } else if (typeof route === 'string') {
        routeToGo = {
          name: route,
          params: {
            ...eventProps,
          },
        };
      }

      // Route generation
      let mode = navigationEvents[key] && navigationEvents[key].mode;
      if (typeof mode === 'function') {
        navigationMode = mode(eventProps);
      } else {
        navigationMode = mode || navigationMode;
      }

      console.log('ROUTE EVENT', routeToGo);
      console.log('MODE EVENT', navigationMode);
      if (routeToGo) {
        navigateTo(routeToGo.name, {
          mode: navigationMode,
          queryParams,
          routeParams: routeToGo.params,
          handleRedirection: redirect,
          clearItems: clear,
          resetHistory,
        });
      } else {
        console.error('No existe la ruta a la que quieres navegar');
      }
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'navigateTo',
    context => (
      key,
      {
        mode: providedMode,
        routeParams: providedRouteParams,
        queryParams,
        handleRedirection,
        // resetHistory, TODO handle this to reset routes
        clearItems,
        saveItems,
      } = {}
    ) => {
      const { componentProps, coreDynamicFunctionGetter } = context;
      let mode = providedMode;
      const pushRoute = coreDynamicFunctionGetter('pushRoute');
      const replaceRoute = coreDynamicFunctionGetter('replaceRoute');
      const { screens, history } = componentProps;
      let routePathOrName = key;
      console.log(' CONTEXT ON NAVIGATION', context);
      const routeParams: { [k: string]: string } = {
        ...params,
        ...providedRouteParams,
      };
      const allQueryParams: { [k: string]: UserInput } = {
        ...qs.parse(history.location.search, {
          ignoreQueryPrefix: true,
        }),
        ...queryParams,
      };
      console.log(
        ' PARAMS',
        history.location.search,
        clearItems,
        saveItems,
        allQueryParams,
        routeParams
      );
      const toClear = clearItems || [];
      (saveItems || []).forEach(item => {
        toClear.push(item)
        if(allQueryParams[item]) {
          localStorage.setItem(`navigateTo.queryParams.${item}`, allQueryParams[item])
        } else if(routeParams[item]){
          localStorage.setItem(`navigateTo.routeParams.${item}`, routeParams[item])
        }else {
          localStorage.removeItem(`navigateTo.queryParams.${item}`)
          localStorage.removeItem(`navigateTo.routeParams.${item}`)
        }
      });
      (toClear).forEach(item => {
        delete allQueryParams[item];
        delete routeParams[item];
      });

      let routeToGo: string;
      if (allQueryParams && handleRedirection) {
        const { from } = allQueryParams;
        if (from) {
          //navigationMode = 'replace'
          delete allQueryParams.from;
          console.log('REDIRECTING TO ', from);
          if (typeof from === 'object' && from['name']) {
            routePathOrName = from.name;
            if (from.queryParams) {
              Object.keys(from.queryParams).forEach(k => {
                allQueryParams[k] = from.queryParams[k];
              });
            }
            if (from.routeParams) {
              Object.keys(from.routeParams).forEach(k => {
                routeParams[k] = from.routeParams[k];
              });
            }
            if (from.mode) {
              mode = from.mode;
            }
          } else if (typeof from === 'string') {
            routePathOrName = from;
          }
        }
      }

      if (routePathOrName.indexOf('/') >= 0) {
        console.log('PATH ROUTE');
        routeToGo = routePathOrName;
      } else {
        if (!screens[routePathOrName]) {
          console.log('NO ROUTE');
          return;
        } else {
          console.log('ROUTE FOUNDED');
          routeToGo = screens[routePathOrName] && screens[routePathOrName].path;
        }
      }

      console.log('query params', qs.stringify(allQueryParams));

      routeToGo = routeParams
        ? Object.keys(routeParams).reduce(
            (route: string, paramName: string) =>
              route.replace(
                ':' + paramName,
                routeParams[paramName] || 'undefined'
              ),
            routeToGo
          )
        : routeToGo;
      routeToGo = allQueryParams
        ? routeToGo + '?' + qs.stringify(allQueryParams)
        : routeToGo;

      console.log('ROUTE', routeToGo);
      if (mode === 'replace') {
        replaceRoute(routeToGo);
      } else {
        pushRoute(routeToGo);
      }
    }
  );

export const SAME_ROUTE_KEY_IDENTIFIER = '_identity';

let controlFlow:
  | {
      listener?: () => boolean;
      pushRoute: any;
      navigateTo: any;
      currentRouteInfo: NavigateToProps;
      routeOverride: RouteNavigationComplex;
    }
  | undefined;

window.addEventListener('popstate', () => {
  console.log('ººº executing listener setted', controlFlow);
  const currentNavigationProps = controlFlow?.currentRouteInfo;
  const route = controlFlow?.routeOverride;
  lastRouteChange = 'back';
  const listener = controlFlow?.listener;
  let listenerHandling = false;
  if (listener) {
    try {
      listenerHandling = listener();
    } catch (e) {
      //Uncontrolled
    }
  }
  if (listenerHandling) {
    return;
  }
  if (route?.key && controlFlow) {
    if (route.key === SAME_ROUTE_KEY_IDENTIFIER) {
      controlFlow.pushRoute();
    } else {
      controlFlow.navigateTo(route.key, {
        ...currentNavigationProps,
        ...route?.navigationProps,
        queryParams: {
          ...(currentNavigationProps || {}).queryParams,
          ...(route.navigationProps || {}).queryParams,
        },
      });
    }
  }
});

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useBackRoute',
    ({
      componentProps: { history },
      coreDynamicFunctionGetter,
      stateHandler: { state },
    }) => (key, navigationProps, listener) => {
      console.log('ººº Setting back route', key, state);
      const pushRoute = coreDynamicFunctionGetter('pushRoute');
      const navigateTo = coreDynamicFunctionGetter('navigateTo');
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        const toSet: {
          listener?: () => boolean;
          pushRoute: any;
          navigateTo: any;
          currentRouteInfo: NavigateToProps;
          routeOverride: RouteNavigationComplex;
        } = {
          listener,
          pushRoute: pushRoute,
          navigateTo: navigateTo,
          currentRouteInfo: {
            queryParams: qs.parse(history.location.search, {
              ignoreQueryPrefix: true,
            }),
            routeParams: {},
          },
          routeOverride:
            key === undefined
              ? { key: SAME_ROUTE_KEY_IDENTIFIER }
              : { key, navigationProps },
        };
        controlFlow = toSet;
        return () => {
          controlFlow = undefined;
          //contextSetter('routeOverride')(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [key, navigationProps]);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('getCurrentRoute', ({ componentProps: props }) => () => {
    console.log(' HISTORY', props.history);
    return queryString.parseUrl(props.history.location.pathname);
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'setCurrentParams',
    ({ variablesHandler: { variablesSetter } }) => newParams => {
      params = newParams;
      variablesSetter('params')(newParams || {});
    }
  );
