/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.fileSystemPaths',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      const exec = require('cordova/exec');
      const channel = require('cordova/channel');

      exports.file = {
        // Read-only directory where the application is installed.
        applicationDirectory: null,
        // Root of app's private writable storage
        applicationStorageDirectory: null,
        // Where to put app-specific data files.
        dataDirectory: null,
        // Cached files that should survive app restarts.
        // Apps should not rely on the OS to delete files in here.
        cacheDirectory: null,
        // Android: the application space on external storage.
        externalApplicationStorageDirectory: null,
        // Android: Where to put app-specific data files on external storage.
        externalDataDirectory: null,
        // Android: the application cache on external storage.
        externalCacheDirectory: null,
        // Android: the external storage (SD card) root.
        externalRootDirectory: null,
        // iOS: Temp directory that the OS can clear at will.
        tempDirectory: null,
        // iOS: Holds app-specific files that should be synced (e.g. to iCloud).
        syncedDataDirectory: null,
        // iOS: Files private to the app, but that are meaningful to other applications (e.g. Office files)
        documentsDirectory: null,
        // BlackBerry10: Files globally available to all apps
        sharedDirectory: null,
      };

      channel.waitForInitialization('onFileSystemPathsReady');
      channel.onCordovaReady.subscribe(function () {
        function after(paths) {
          for (const k in paths) {
            exports.file[k] = paths[k];
          }
          channel.initializationComplete('onFileSystemPathsReady');
        }
        exec(after, null, 'File', 'requestAllPaths', []);
      });
    }
  );
};
