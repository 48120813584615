/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.fileSystems-roots',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      // Map of fsName -> FileSystem.
      let fsMap = null;
      const FileSystem = require('./FileSystem');
      const exec = require('cordova/exec');

      // Overridden by Android, BlackBerry 10 and iOS to populate fsMap.
      require('./fileSystems').getFs = function (name, callback) {
        function success(response) {
          fsMap = {};
          for (let i = 0; i < response.length; ++i) {
            const fsRoot = response[i];
            if (fsRoot) {
              const fs = new FileSystem(fsRoot.filesystemName, fsRoot);
              fsMap[fs.name] = fs;
            }
          }
          callback(fsMap[name]);
        }

        if (fsMap) {
          callback(fsMap[name]);
        } else {
          exec(success, null, 'File', 'requestAllFileSystems', []);
        }
      };
    }
  );
};
