/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-advanced-http.error-codes',
    function (require, exports, module) {
      module.exports = {
        GENERIC: -1,
        SSL_EXCEPTION: -2,
        SERVER_NOT_FOUND: -3,
        TIMEOUT: -4,
        UNSUPPORTED_URL: -5,
        NOT_CONNECTED: -6,
        POST_PROCESSING_FAILED: -7,
        ABORTED: -8,
      };
    }
  );
};
