/* eslint-disable no-undef */
export const init = () => {
  cordova.define(
    'cordova-plugin-file.Entry',
    function (require, exports, module) {
      /*
       *
       * Licensed to the Apache Software Foundation (ASF) under one
       * or more contributor license agreements.  See the NOTICE file
       * distributed with this work for additional information
       * regarding copyright ownership.  The ASF licenses this file
       * to you under the Apache License, Version 2.0 (the
       * "License"); you may not use this file except in compliance
       * with the License.  You may obtain a copy of the License at
       *
       *   http://www.apache.org/licenses/LICENSE-2.0
       *
       * Unless required by applicable law or agreed to in writing,
       * software distributed under the License is distributed on an
       * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
       * KIND, either express or implied.  See the License for the
       * specific language governing permissions and limitations
       * under the License.
       *
       */

      const argscheck = require('cordova/argscheck');
      const exec = require('cordova/exec');
      const FileError = require('./FileError');
      const Metadata = require('./Metadata');

      /**
       * Represents a file or directory on the local file system.
       *
       * @param isFile
       *            {boolean} true if Entry is a file (readonly)
       * @param isDirectory
       *            {boolean} true if Entry is a directory (readonly)
       * @param name
       *            {DOMString} name of the file or directory, excluding the path
       *            leading to it (readonly)
       * @param fullPath
       *            {DOMString} the absolute full path to the file or directory
       *            (readonly)
       * @param fileSystem
       *            {FileSystem} the filesystem on which this entry resides
       *            (readonly)
       * @param nativeURL
       *            {DOMString} an alternate URL which can be used by native
       *            webview controls, for example media players.
       *            (optional, readonly)
       */
      function Entry(
        isFile,
        isDirectory,
        name,
        fullPath,
        fileSystem,
        nativeURL
      ) {
        this.isFile = !!isFile;
        this.isDirectory = !!isDirectory;
        this.name = name || '';
        this.fullPath = fullPath || '';
        this.filesystem = fileSystem || null;
        this.nativeURL = nativeURL || null;
      }

      /**
       * Look up the metadata of the entry.
       *
       * @param successCallback
       *            {Function} is called with a Metadata object
       * @param errorCallback
       *            {Function} is called with a FileError
       */
      Entry.prototype.getMetadata = function (successCallback, errorCallback) {
        argscheck.checkArgs('FF', 'Entry.getMetadata', arguments);
        const success =
          successCallback &&
          function (entryMetadata) {
            const metadata = new Metadata({
              size: entryMetadata.size,
              modificationTime: entryMetadata.lastModifiedDate,
            });
            successCallback(metadata);
          };
        const fail =
          errorCallback &&
          function (code) {
            errorCallback(new FileError(code));
          };
        exec(success, fail, 'File', 'getFileMetadata', [this.toInternalURL()]);
      };

      /**
       * Set the metadata of the entry.
       *
       * @param successCallback
       *            {Function} is called with a Metadata object
       * @param errorCallback
       *            {Function} is called with a FileError
       * @param metadataObject
       *            {Object} keys and values to set
       */
      Entry.prototype.setMetadata = function (
        successCallback,
        errorCallback,
        metadataObject
      ) {
        argscheck.checkArgs('FFO', 'Entry.setMetadata', arguments);
        exec(successCallback, errorCallback, 'File', 'setMetadata', [
          this.toInternalURL(),
          metadataObject,
        ]);
      };

      /**
       * Move a file or directory to a new location.
       *
       * @param parent
       *            {DirectoryEntry} the directory to which to move this entry
       * @param newName
       *            {DOMString} new name of the entry, defaults to the current name
       * @param successCallback
       *            {Function} called with the new DirectoryEntry object
       * @param errorCallback
       *            {Function} called with a FileError
       */
      Entry.prototype.moveTo = function (
        parent,
        newName,
        successCallback,
        errorCallback
      ) {
        argscheck.checkArgs('oSFF', 'Entry.moveTo', arguments);
        const fail =
          errorCallback &&
          function (code) {
            errorCallback(new FileError(code));
          };
        const srcURL = this.toInternalURL();
        // entry name
        const name = newName || this.name;
        const success = function (entry) {
          if (entry) {
            if (successCallback) {
              // create appropriate Entry object
              const newFSName =
                entry.filesystemName ||
                (entry.filesystem && entry.filesystem.name);
              const fs = newFSName
                ? new FileSystem(newFSName, { name: '', fullPath: '/' })
                : new FileSystem(parent.filesystem.name, {
                    name: '',
                    fullPath: '/',
                  });
              const result = entry.isDirectory
                ? new (require('./DirectoryEntry'))(
                    entry.name,
                    entry.fullPath,
                    fs,
                    entry.nativeURL
                  )
                : new (require('cordova-plugin-file.FileEntry'))(
                    entry.name,
                    entry.fullPath,
                    fs,
                    entry.nativeURL
                  );
              successCallback(result);
            }
          } else {
            // no Entry object returned
            if (fail) {
              fail(FileError.NOT_FOUND_ERR);
            }
          }
        };

        // copy
        exec(success, fail, 'File', 'moveTo', [
          srcURL,
          parent.toInternalURL(),
          name,
        ]);
      };

      /**
       * Copy a directory to a different location.
       *
       * @param parent
       *            {DirectoryEntry} the directory to which to copy the entry
       * @param newName
       *            {DOMString} new name of the entry, defaults to the current name
       * @param successCallback
       *            {Function} called with the new Entry object
       * @param errorCallback
       *            {Function} called with a FileError
       */
      Entry.prototype.copyTo = function (
        parent,
        newName,
        successCallback,
        errorCallback
      ) {
        argscheck.checkArgs('oSFF', 'Entry.copyTo', arguments);
        const fail =
          errorCallback &&
          function (code) {
            errorCallback(new FileError(code));
          };
        const srcURL = this.toInternalURL();
        // entry name
        const name = newName || this.name;
        // success callback
        const success = function (entry) {
          if (entry) {
            if (successCallback) {
              // create appropriate Entry object
              const newFSName =
                entry.filesystemName ||
                (entry.filesystem && entry.filesystem.name);
              const fs = newFSName
                ? new FileSystem(newFSName, { name: '', fullPath: '/' })
                : new FileSystem(parent.filesystem.name, {
                    name: '',
                    fullPath: '/',
                  });
              const result = entry.isDirectory
                ? new (require('./DirectoryEntry'))(
                    entry.name,
                    entry.fullPath,
                    fs,
                    entry.nativeURL
                  )
                : new (require('cordova-plugin-file.FileEntry'))(
                    entry.name,
                    entry.fullPath,
                    fs,
                    entry.nativeURL
                  );
              successCallback(result);
            }
          } else {
            // no Entry object returned
            if (fail) {
              fail(FileError.NOT_FOUND_ERR);
            }
          }
        };

        // copy
        exec(success, fail, 'File', 'copyTo', [
          srcURL,
          parent.toInternalURL(),
          name,
        ]);
      };

      /**
       * Return a URL that can be passed across the bridge to identify this entry.
       */
      Entry.prototype.toInternalURL = function () {
        if (this.filesystem && this.filesystem.__format__) {
          return this.filesystem.__format__(this.fullPath, this.nativeURL);
        }
      };

      /**
       * Return a URL that can be used to identify this entry.
       * Use a URL that can be used to as the src attribute of a <video> or
       * <audio> tag. If that is not possible, construct a cdvfile:// URL.
       */
      Entry.prototype.toURL = function () {
        if (this.nativeURL) {
          return this.nativeURL;
        }
        // fullPath attribute may contain the full URL in the case that
        // toInternalURL fails.
        return this.toInternalURL() || 'file://localhost' + this.fullPath;
      };

      /**
       * Backwards-compatibility: In v1.0.0 - 1.0.2, .toURL would only return a
       * cdvfile:// URL, and this method was necessary to obtain URLs usable by the
       * webview.
       * See CB-6051, CB-6106, CB-6117, CB-6152, CB-6199, CB-6201, CB-6243, CB-6249,
       * and CB-6300.
       */
      Entry.prototype.toNativeURL = function () {
        console.log("DEPRECATED: Update your code to use 'toURL'");
        return this.toURL();
      };

      /**
       * Returns a URI that can be used to identify this entry.
       *
       * @param {DOMString} mimeType for a FileEntry, the mime type to be used to interpret the file, when loaded through this URI.
       * @return uri
       */
      Entry.prototype.toURI = function (mimeType) {
        console.log("DEPRECATED: Update your code to use 'toURL'");
        return this.toURL();
      };

      /**
       * Remove a file or directory. It is an error to attempt to delete a
       * directory that is not empty. It is an error to attempt to delete a
       * root directory of a file system.
       *
       * @param successCallback {Function} called with no parameters
       * @param errorCallback {Function} called with a FileError
       */
      Entry.prototype.remove = function (successCallback, errorCallback) {
        argscheck.checkArgs('FF', 'Entry.remove', arguments);
        const fail =
          errorCallback &&
          function (code) {
            errorCallback(new FileError(code));
          };
        exec(successCallback, fail, 'File', 'remove', [this.toInternalURL()]);
      };

      /**
       * Look up the parent DirectoryEntry of this entry.
       *
       * @param successCallback {Function} called with the parent DirectoryEntry object
       * @param errorCallback {Function} called with a FileError
       */
      Entry.prototype.getParent = function (successCallback, errorCallback) {
        argscheck.checkArgs('FF', 'Entry.getParent', arguments);
        const fs = this.filesystem;
        const win =
          successCallback &&
          function (result) {
            const DirectoryEntry = require('./DirectoryEntry');
            const entry = new DirectoryEntry(
              result.name,
              result.fullPath,
              fs,
              result.nativeURL
            );
            successCallback(entry);
          };
        const fail =
          errorCallback &&
          function (code) {
            errorCallback(new FileError(code));
          };
        exec(win, fail, 'File', 'getParent', [this.toInternalURL()]);
      };

      module.exports = Entry;
    }
  );
};
